<script lang="ts">
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 14.043259 14.043259" {...$$props}>
  <g id="layer1" transform="translate(-96.883583,-124.44718)">
    <path
      d="m 103.78704,126.39745 a 5.0735625,5.0735625 0 0 0 -0.77463,0.0775 5.0735625,5.0735625 0 0 0 -4.011127,3.72174 h -1.375626 l 1.523938,2.52749 1.524455,-2.52749 h -1.090373 a 4.5098332,4.5098332 0 0 1 3.527953,-3.16725 4.5098332,4.5098332 0 0 1 2.46186,0.25012 l 0.48008,-0.40463 a 5.0735625,5.0735625 0 0 0 -2.26653,-0.47749 z m 4.8736,3.79925 -1.52445,2.52749 h 1.09967 a 4.5098332,4.5098332 0 0 1 -3.53622,3.18431 4.5098332,4.5098332 0 0 1 -3.01687,-0.51832 l -0.4563,0.38447 a 5.0735625,5.0735625 0 0 0 3.57188,0.68833 5.0735625,5.0735625 0 0 0 4.01991,-3.73879 h 1.36632 z"
    />
    <rect
      width="2.4409497"
      height="2.4409497"
      x="165.2141"
      y="18.269947"
      transform="rotate(45)"
    />
  </g>
</BaseSvg>
