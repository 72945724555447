<script lang="ts">
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 14.043259 14.043259" {...$$props}>
  <g id="layer1" transform="translate(-80.599342,-124.44718)">
    <path
      transform="rotate(90)"
      d="m 132.00537,-87.084373 h 5.74304 v 5.743042 h -5.74304 z"
    />
    <path
      d="m 81.341389,125.18912 v 6.1596 h 0.616301 v -5.54412 h 11.327632 v 11.32767 h -5.544081 v 0.61626 h 6.15956 v -12.55941 z"
    />
    <path
      d="m 91.532525,127.76997 -2.015898,0.4992 0.526066,0.52607 -2.720247,2.71973 0.465088,0.46508 2.720247,-2.71973 0.525549,0.52555 z"
    />
  </g>
</BaseSvg>
