<script lang="ts">
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 14.043258 14.043258" {...$$props}>
  <g id="layer1" transform="translate(-115.7075,-124.44718)">
    <path
      d="m 122.72929,125.18925 c -0.50806,0.84251 -1.01639,1.685 -1.52445,2.5275 0.35225,0 0.70453,0 1.05678,0 v 1.83554 h 0.93483 v -1.83554 c 0.35225,0 0.70453,0 1.05678,0 -0.50804,-0.84249 -1.0159,-1.685 -1.52394,-2.5275 z"
    />
    <path
      d="m 122.26162,133.38565 v 1.83503 c -0.35225,0 -0.70453,0 -1.05678,0 0.50804,0.84249 1.01641,1.685 1.52445,2.5275 0.50806,-0.84251 1.01588,-1.685 1.52394,-2.5275 -0.35225,0 -0.70453,0 -1.05678,0 v -1.83503 z"
    />
    <path
      d="m 126.481,129.94452 c 0,0.35225 0,0.70453 0,1.05678 h -1.83503 v 0.93483 h 1.83503 c 0,0.35225 0,0.70453 0,1.05678 0.84249,-0.50804 1.685,-1.0159 2.5275,-1.52394 -0.84251,-0.50806 -1.685,-1.01639 -2.5275,-1.52445 z"
    />
    <path
      d="m 118.97707,129.94452 c -0.84249,0.50804 -1.685,1.01641 -2.5275,1.52445 0.84251,0.50806 1.685,1.01588 2.5275,1.52394 0,-0.35225 0,-0.70453 0,-1.05678 h 1.83554 v -0.93483 h -1.83554 c 0,-0.35225 0,-0.70453 0,-1.05678 z"
    />
  </g>
</BaseSvg>
